
import Button from "@/components/base/Button"
import TemplatePreview from "../components/TemplatePreview"
import { setModifications } from "../helpers/handler"
import { transformJob } from "../transformers/job"
import { getTemplates } from "@/services/v2/marketing.service"

import { routeToCreatobat } from "@/helpers/routing"

export default {
    name: "TemplatePage",
    layout: "fullscreen",
    components: {
        Button,
        TemplatePreview,
    },
    props: {
        entity: {
            type: Object,
            required: true,
        },
    },
    fetchOnServer: false,
    async fetch() {
        if (this.templates.length === 0) {
            this.templates = await this.$api.run(getTemplates).request
        }
        if (!this.entity.job) return
        const job = this.entity.job
        setModifications(transformJob(job))
    },
    data: () => ({
        routeToCreatobat,
        templates: [],
    }),
    watch: {
        entity() {
            this.$fetch()
        },
    },
}
