import Vue from "vue"
export const MODE_PLAYER = "player"
export const MODE_INTERACTIVE = "interactive"
export const PREVIEW_KEY = "public-i8w9jq5z8ql2yk2vpuy7ydor"

let templates = []
let editor = null

export const settings = Vue.observable({
    modifications: {},
    mode: null,
    width: 0,
    height: 0,
    dynamicElements: [],
})

let previewModule = null

async function getClass() {
    if (previewModule) return previewModule.Preview
    previewModule = await import("@creatomatefork/preview")
    return previewModule.Preview
}

/** Creates singleton editor */
export async function createEditor(
    el,
    template = null,
    mode = MODE_INTERACTIVE
) {
    if (process.server) {
        console.error("Cannot create editor on server")
        return
    }
    const Preview = await getClass()
    return new Promise((resolve) => {
        const instance = new Preview(el, mode, PREVIEW_KEY)
        instance.onReady = () => {
            instance.loadTemplate(template)
        }
        instance.onLoad = () => {
            instance.setModifications(settings.modifications).then(() => {
                settings.dynamicElements = getDynamicElementsOf(
                    editor.getSource().elements
                )
            })
            settings.width = instance.state.width
            settings.height = instance.state.height
            editor = instance
            templates.push(instance)
            /** Not yet perfect, need to revert creatomate interactive mode zoom-out scale */
            instance.setZoom("fixed", 0.7)
            resolve(instance)
        }
    })
}

export async function createTemplate(el, template = null) {
    if (process.server) {
        console.error("Cannot create template on server")
        return
    }
    const Preview = await getClass()
    return new Promise((resolve) => {
        const instance = new Preview(el, MODE_INTERACTIVE, PREVIEW_KEY)
        instance.onReady = () => {
            instance.loadTemplate(template)
        }
        instance.onLoad = () => {
            instance.setModifications(settings.modifications)
            templates.push(instance)
            resolve(instance)
        }
    })
}

export async function setModifications(modifications) {
    settings.modifications = modifications
    templates.forEach((instance) => {
        instance.setModifications(modifications)
    })
}

export async function addModifications(modifications) {
    settings.modifications = { ...settings.modifications, ...modifications }
    templates.forEach((instance) => {
        instance.applyModifications(modifications)
    })
}

export async function setMode(mode, settingOnly = false) {
    if (!editor) return
    settings.mode = mode
    if (settingOnly) {
        editor.setActiveElements([])
        return
    }
    editor.setMode(mode)
}

export function setTime(time, global = false) {
    if (!editor) return
    editor.setTime(time)
    if (global) {
        templates.forEach((instance) => {
            instance.setTime(time)
        })
    }
    setZoom()
}

export function getEditor() {
    return editor
}

export function getEditorSource() {
    if (!editor) return null
    return editor.getSource()
}

export function setHeight(height) {
    settings.height = height
    addModifications({ height })
    setZoom()
}

export function setWidth(width) {
    settings.width = width
    addModifications({ width })
    setZoom()
}

function setZoom() {
    const ratio1 = settings.height / settings.width / 2
    const ratio2 = settings.width / settings.height / 2
    const zoom = Math.max(ratio1, ratio2)
    if (zoom === Infinity || zoom < 0.1) return
    editor.setZoom("fixed", zoom)
}

function getDynamicElementsOf(elements) {
    if (!elements) return []
    const dynamicElements = []
    elements.forEach((element) => {
        if (element.dynamic && !element.locked) {
            dynamicElements.push(element)
        }
        if (element.elements?.length > 0) {
            dynamicElements.push(...getDynamicElementsOf(element.elements))
        }
    })
    return dynamicElements
}
