import { getImage } from "@/helpers/image"

export function transformJob(job) {
    const videos = getVideos(job)
    const length = videos.length
    return {
        "Video 1.source": videos[0 % length],
        "Video 2.source": videos[1 % length],
        "Video 3.source": videos[2 % length],
        "Video 4.source": videos[3 % length],
        "Video 5.source": videos[4 % length],
        "Name.text": job.name,
        "Handle.text": job.business.name,
        "Text.text": getContentBlockText(job.content_blocks),
        "HowToBecome.text": `Wie wird man eigentlich zum [size 150%]${job.name}?`,
        "Logo.source": "https://oabat.de" + getImage(job.business.logo).src,
    }
}

function getContentBlockText(contentBlocks) {
    if (!contentBlocks?.length) return "Gehe jetzt steil mit uns!"
    const blocks = contentBlocks[0].data.items.map((el) => el.data.headline)
    return blocks.join("\n")
}

function getVideos(job) {
    let videos = []
    if (job.video) videos.push(job.video)
    if (job.content_blocks) {
        const blockVideos = job.content_blocks?.[0].data.items.reduce(
            (acc, item) => {
                if (item.data.videos) {
                    acc.push(...item.data.videos)
                }
                return acc
            },
            []
        )
        videos.push(...blockVideos)
    }
    if (videos.length === 0) return [null]
    return videos.map((video) => {
        return (
            video.files?.filter((file) => file.rendition === "720p")?.at(0)
                ?.link || video.files?.at(0)?.link
        )
    })
}
