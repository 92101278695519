
import { createTemplate } from "../helpers/handler"
import ProgressLinear from "@/components/base/ProgressLinear"

export default {
    name: "PreviewComponent",
    components: { ProgressLinear },
    props: {
        template: {
            type: String,
            required: true,
        },
        width: {
            type: Number,
            default: 300,
        },
        expectedHeight: {
            type: Number,
            default: 300,
        },
    },
    emits: ["ready"],
    data: () => ({
        preview: null,
        originalHeight: 0,
        originalWidth: 0,
        isReady: false,
    }),
    computed: {
        internalWidth() {
            return this.width
        },
        internalHeight() {
            if (!this.originalWidth) return this.expectedHeight
            const factor = this.width / this.originalWidth
            return this.originalHeight * factor
        },
    },
    async mounted() {
        this.preview = await createTemplate(this.$refs.preview, this.template)
        this.preview.onLoadComplete = () => {
            this.isReady = true
            this.$emit("ready")
        }
        const state = this.preview.state
        this.originalWidth = state.width / 3
        this.originalHeight = state.height / 3
    },
    beforeDestroy() {
        this.preview?.dispose()
    },
}
